import { isIOSWebView, isAndWebView } from 'src/utils/utility';
import { storageMartgoTkn, storageMembersTkn } from './sso';

/**
 * @name 자동로그인 설정 값 저장
 * @param auto  Y/N
 */
export const bridgeSetAutoLogin = (auto: string) => {
  try {
    localStorage.setItem('AUTO_LOGIN_STATE', auto);

    if (isIOSWebView) {
      webkit.messageHandlers.setAutoLogin.postMessage({
        auto,
      });
    } else if (isAndWebView) {
      window.binder?.setAutoLogin(auto);
    }
  } catch (e) {
    console.log('error bridge setAutoLogin');
  }
};

/**
 * @name 로그인관련 정보 저장
 * @param auto 자동로그인 Y or N
 * @param userId 유저아이디
 * @param appToken 마트GO 앱토큰
 * @param ssoAToken SSO 엑섹스 토큰
 * @param ssoRToken SSO 리플러쉬 토큰
 * @param ssoToken  SSO 토큰
 * @param ssoIsOwnLgn SSO 자체앱 로그인사용 여부 N 고정
 */
export const bridgeSetLoginInfo = (
  appToken: string,
  appReToken: string,
  ssoAToken: string,
  ssoRToken: string,
  ssoToken: string,
): void => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.setLoginInfo.postMessage({
        userId: '',
        appToken: appToken,
        appReToken: appReToken,
        ssoAToken: ssoAToken,
        ssoRToken: ssoRToken,
        ssoToken: ssoToken,
        ssoIsOwnLgn: 'N',
      });
    } else if (isAndWebView) {
      window.binder?.setLoginInfo(
        '',
        appToken,
        appReToken,
        ssoAToken,
        ssoRToken,
        ssoToken,
        'N',
      );
    }
  } catch (e) {
    console.log('error bridge setLoginInfo');
  }
};

/**
 * 외부화면 이동
 * @param url 이동할 경로
 */
export const bridgeOpenBrowser = (url: string): void => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.openBrowser.postMessage({
        url: url,
      });
    } else if (isAndWebView) {
      window.binder?.openBrowser(url);
    } else {
      window.open(url);
    }
  } catch (e) {
    console.log('error bridge openBrowser');
  }
};

/**
 * 임시저장파일(캐시) 삭제
 * @param msg 삭제 후 노출할 토스트 메세지
 */
export const bridgeDeleteCache = (msg: string): void => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.deleteCache.postMessage({ message: msg });
    } else if (isAndWebView) {
      window.binder?.deleteCache(msg);
    }
  } catch (e) {
    console.log('error bridge deleteCache');
  }
};

/**
 * 설정 값 저장
 * @param key PYN:PUSH, PN:알림권한, PC:카메라권한, PP:전화권한, PS:갤러리접근권한
 * @param value 설정 값 Y/N
 * @param onProcess 완료 후 실행 함수
 */
export const bridgeSetSettingInfo = (key: string, value: string) => {
  try {
    const callbackName = 'callbackSetSettingInfo';
    if (isIOSWebView) {
      webkit.messageHandlers.setSettingInfo.postMessage({
        key: key,
        value: value,
        callback: callbackName,
      });
    } else if (isAndWebView) {
      window.binder?.setSettingInfo(key, value, callbackName);
    }

    window[callbackName] = () => {
      // 설정화면으로 이동하면 Y, 취소 시 N
    };
  } catch (e) {
    console.log('error bridge setSettingInfo');
  }
};

/**
 * 로그아웃 - 설정된 토큰 초기화
 */
export const bridgeLogout = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.logout.postMessage({});
    } else if (isAndWebView) {
      window.binder?.logout();
    }
  } catch (e) {
    console.log('error bridge logout');
  }
};

/**
 * 스플래시 닫기
 */
export const bridgeHideSplash = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.hideSplash.postMessage({});
    } else if (isAndWebView) {
      window.binder?.hideSplash();
    }
  } catch (e) {
    console.log('error bridge hideSplash');
  }
};

/**
 * OS 설정으로 이동
 */
export const bridgeGoOsSettingPage = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.goOsSettingPage.postMessage({});
    } else if (isAndWebView) {
      window.binder?.goOsSettingPage();
    }
  } catch (e) {
    console.log('error bridge goOsSettingPage');
  }
};

/**
 * @name 토큰조회 API Util 토큰이 storage에 없는 경우 앱에서 조회
 */
export const bridgeGetToken = async () => {
  try {
    const callbackName = 'callbackGetLoginInfo';
    if (isIOSWebView) {
      webkit.messageHandlers.getLoginInfo.postMessage({
        callback: callbackName,
      });
    } else if (isAndWebView) {
      window.binder?.getLoginInfo(callbackName);
    }

    window[callbackName] = (
      access_token,
      refresh_token,
      acesTkn,
      rnwTkn,
      ssoTkn,
    ) => {
      const martGoTkn = {
        access_token: access_token,
        refresh_token: refresh_token,
      };
      const membersTkn = {
        acesTkn: acesTkn,
        rnwTkn: rnwTkn,
        ssoTkn: ssoTkn,
      };

      storageMartgoTkn(martGoTkn); //마트GO 토큰 저장
      if (acesTkn) storageMembersTkn(membersTkn); //멤버스 토큰 저장
    };
  } catch (e) {
    console.log('error bridge API Util getLoginInfo');
  }

  //자동로그인 설정 값 조회
  try {
    const callbackName = 'callbackGetAutoLogin';
    if (isIOSWebView) {
      webkit.messageHandlers.getAutoLogin.postMessage({
        callback: callbackName,
      });
    } else if (isAndWebView) {
      window.binder?.getAutoLogin(callbackName);
    }

    window[callbackName] = auto => {
      localStorage.setItem('AUTO_LOGIN_STATE', auto);
    };
  } catch (e) {
    console.log('error bridge API Util getAutoLogin');
  }
};

/**
 * @name 앱종료
 */
export const bridgeQuit = () => {
  if (isIOSWebView) {
    webkit.messageHandlers.quit.postMessage({});
  } else if (isAndWebView) {
    window.binder?.quit();
  }
};

/**
 * @name 앱스키마로 기타 앱 실행하기
 * @param scheme 앱 스키마
 * @param appId 스토어 다운로드용
 */
export const bridgeOpenAppScheme = (scheme: string, appId: string) => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.openAppScheme.postMessage({
        scheme: scheme,
        appId: appId,
      });
    } else if (isAndWebView) {
      window.binder?.openAppScheme(scheme, appId);
    }
  } catch (e) {
    console.log('error bridge openAppScheme');
  }
};

/**
 * @name 롯데마트몰 이동
 */
export const bridgeOpenLotteMartApp = (targetUrl: string) => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.openLotteMartApp.postMessage({
        host: '',
        targetUrl: targetUrl,
      });
    } else if (isAndWebView) {
      window.binder?.openLotteMartApp('', targetUrl);
    }
  } catch (e) {
    console.log('error bridge openLotteMartApp');
  }
};

/**
 * @name 앱스키마로 스토어로 이동
 */
export const bridgeOpenAppSchemeStore = () => {
  try {
    if (isIOSWebView) {
      //iOS는 스키마가 없어서 브라우저로 열기
      const update_url = 'https://itunes.apple.com/app/id1615706998';
      bridgeOpenBrowser(update_url);
    } else if (isAndWebView) {
      //스키마, 패키지명
      window.binder?.openAppScheme(
        'market://details?id=com.lottemart.bottlebunker',
        'com.lottemart.bottlebunker',
      );
    }
  } catch (e) {
    console.log('error bridge restart');
  }
};
/**
 * @name 보틀벙커 이동
 */
export const bridgeOpeBottleBunkerApp = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.openAppScheme.postMessage({
        scheme:
          'bblottemart://sns?url=https://m.coupon.lottemart.com/sso/view/bottle/index.ssp',
        appId: 'https://itunes.apple.com/app/id1615706998',
      });
    } else if (isAndWebView) {
      //스키마, 패키지명
      window.binder?.openAppScheme(
        'bblottemart://sns?url=com.lottemart.bottlebunker',
        'com.lottemart.bottlebunker',
      );
    }
  } catch (e) {
    console.log('error bridge openLotteMartApp');
  }
};

/**
 * @name 스캔앤고 내부 웹뷰 닫기
 */
export const bridgeClose = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.close.postMessage({});
    } else if (isAndWebView) {
      window.binder?.close();
    }
  } catch (e) {
    console.log('error bridge close');
  }
};

/**
 * @name 바코드 열기(화면 밝기 최대)
 */
export const bridgeBarcodeOpen = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.barcodeOpen.postMessage({});
    } else if (isAndWebView) {
      window.binder?.barcodeOpen();
    }
  } catch (e) {
    console.log('error bridge barcodeOpen');
  }
};

/**
 * @name 바코드 닫기(화면 밝기 원복)
 */
export const bridgeBarcodeClose = () => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.barcodeClose.postMessage({});
    } else if (isAndWebView) {
      window.binder?.barcodeClose();
    }
  } catch (e) {
    console.log('error bridge barcodeClose');
  }
};

/**
 * @name string 값 저장
 * @param key 키
 * @param value 값
 */
export const bridgeSetKeyValue = (key, value) => {
  try {
    if (isIOSWebView) {
      webkit.messageHandlers.setKeyValue.postMessage({
        key: key,
        value: value,
      });
    } else if (isAndWebView) {
      window.binder?.setKeyValue(key, value);
    }
  } catch (e) {
    console.log('error bridge setKeyValue');
  }
};

/**
 * APP Version 정보 조회
 *
 */

export const bridgeGetSettingInfo = (callback: (returnObj) => void): void => {
  try {
    if (isAndWebView) {
      window.binder?.getSettingInfo('AV', 'callbackGetSettingInfo');
    } else if (isIOSWebView) {
      webkit.messageHandlers.getSettingInfo.postMessage({
        key: 'AV',
        callback: 'callbackGetSettingInfo',
      });
    }
  } catch (e) {
    console.log('getSettingInfo bridge error');
  }

  window.callbackGetSettingInfo = (key, value) => {
    const returnObj = { key: '', value: '' };

    returnObj['key'] = key;
    returnObj['value'] = value;
    callback(returnObj);
  };
};

/**
 * 줌아웃기능 웹뷰 열기
 * @param title 상단 타이틀
 * @param url 이동할 url (ex. `${window.location.origin}/product/placefind?str_cd=${storeDetailInfo.str_cd}&str_loc_cd=${locCd}`;)
 */
export const bridgeOpenMapWebview = (title: string, url: string): void => {
  try {
    if (isAndWebView) {
      window.binder?.openMapWebview(title, url);
    } else if (isIOSWebView) {
      webkit.messageHandlers.openMapWebview.postMessage({
        title,
        url,
      });
    }
  } catch (e) {
    console.log('openMapWebview bridge error');
  }
};

/**
 * url 링크 공유하기
 * @param title 제목
 * @param url 공유할URL
 */
export const bridgeOpenUrlShare = (subject: string, url: string): void => {
  try {
    if (isAndWebView) {
      window.binder?.openUrlShare(subject, url);
    } else if (isIOSWebView) {
      webkit.messageHandlers.openUrlShare.postMessage({
        subject,
        url,
      });
    }
  } catch (e) {
    console.log('openMapWebview bridge error');
  }
};

/**
 * 서브 웹뷰 열기
 * @param url 이동할 url (ex. `${window.location.origin}/product/placefind?str_cd=${storeDetailInfo.str_cd}&str_loc_cd=${locCd}`;)
 * @param close 우측상단 종료 버튼 유무
 */
export const bridgeOpenSubWebview = (url: string, close: string): void => {
  try {
    if (isAndWebView) {
      window.binder?.openSubWebview(url, close);
    } else if (isIOSWebView) {
      webkit.messageHandlers.openSubWebview.postMessage({
        url,
        close,
      });
    }
  } catch (e) {
    console.log('openMapWebview bridge error');
  }
};

/**
 * 이미지 캡쳐 웹뷰 팝업 열기
 * @param url 이동할 url (ex. `${window.location.origin}/product/placefind?str_cd=${storeDetailInfo.str_cd}&str_loc_cd=${locCd}`;)
 *
 */
export const bridgeOpenSharePopup = (url: string): void => {
  try {
    if (isAndWebView) {
      window.binder?.openSharePopup(url);
    } else if (isIOSWebView) {
      webkit.messageHandlers.openSharePopup.postMessage({
        url,
      });
    }
  } catch (e) {
    console.log('openSharePopup bridge error');
  }
};

/**
 * 이미지 캡쳐 후 공유하기
 *
 */
export const bridgeImageShare = (): void => {
  try {
    if (isAndWebView) {
      window.binder?.openImageShare();
    } else if (isIOSWebView) {
      webkit.messageHandlers.openImageShare.postMessage({});
    }
  } catch (e) {
    console.log('openImageShare bridge error');
  }
};
